import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import './projects.css'

import { PROJECTS } from '../data'
import ProjectModal from './project_modal'
import InfoSlide from './info_slide'
import Img1 from '../components/images/kanye-small.png'

function Projects() {

  const [showProject, setShowProject] = useState(null)
  const [showInfo, setShowInfo] = useState(null)

  return (
    <div className="project-container">
      <h3 style={{textAlign: "center"}}>SELECTED PROJECTS</h3><br />

      {PROJECTS.map(({project, title, year, description, img, img2}) => {
        return (
          <>
            <div className="project-title" key={project} onClick={() => setShowProject(project)} onMouseEnter={() => setShowInfo(project)} onMouseLeave={() => setShowInfo(null)}>
              <h3 className="project-year">{year}</h3>

              <h2 className="link">
                {title}
              </h2>

              <AnimatePresence>
                {showInfo === project &&
                  <motion.div className="project-description"
                  initial={{ x: "50vw", opacity: 0 }}
                  animate={{ x: 0, opacity: 1, transition: { delay: 0.1, duration: 0.5, ease: [0.20, 0, 0.13, 1] } }}
                  exit={{ x: "50vw", opacity: 0 }}>
                    <p style={{fontSize: "1rem"}}>{description}</p></motion.div>
                }
              </AnimatePresence>

                {showInfo === project &&
                <motion.div
                initial={{ x: 150 }}
                animate={{ x: 0, transition: { duration: 0.5, ease: [0.20, 0, 0.13, 1] } }}
                exit={{ x: 150, transition: { duration: 0.5, ease: [0.20, 0, 0.13, 1] }  }}
                style={{marginLeft: "auto"}}
                >
                  →
                </motion.div>
                }

                <motion.div className="img-thumb"
                style={showInfo !== project ? {marginLeft: "auto"} : {marginLeft: 0} }
                >
                  <img className="img-thumb" src={img} alt="" />
                </motion.div>




            </div>
          </>
        )
      })}

      <ProjectModal
        open={showProject}
        onClose={() => setShowProject(null)}
      />

      {/* <InfoSlide projects={PROJECTS} showInfo={showInfo}/> */}

      <div className="socials">
        <a href="https://www.instagram.com/boytillek/?hl=nl" target="_blank" rel="noreferrer">Instagram</a>
        <a href="https://www.linkedin.com/in/boytillekens" target="_blank" rel="noreferrer">Linkedin</a>
      </div>
    </div>
  )
}

export default Projects

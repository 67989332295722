import kanyeThumb from './components/images/kanye-small.png'
import kanyeThumb2 from './components/images/kanye-small2.png'
import wrongmanThumb from './components/images/wrongmang-small.png'
import nerdThumb from './components/images/nerd-thumb.png'
import diceThumb from './components/images/dice-thumb.png'
import cabinThumb from './components/images/cabin-thumb.png'
import mayaThumb from './components/images/maya-thumb.png'
import idiotboxThumb from './components/images/idiotbox-thumb.png'
import beaconThumb from './components/images/beacon-thumb.png'

export const PROJECTS = [
  {
    project: "grad",
    title: 'Kanye West',
    year: 2022,
    description: `Online advertisement promoting the 15th anniversary of Kanye West's "Graduation" album`,
    client: 'Universal Music Group, 2022',
    img: kanyeThumb,
    img2: kanyeThumb2
  },
  {
    project: "wm",
    title: 'Wrong Man',
    year: 2023,
    description: 'Music video for the song "Wait" by Wrong Man',
    client: 'Thirty Something Records, 2023',
    img: wrongmanThumb
  },
  {
    project: "nerd",
    title: 'N*E*R*D',
    year: 2022,
    description: `Online advertisement promoting the release of a new line of merchandise celebrating the 20th anniversary of N*E*R*D's debut album "In Search Of..."`,
    client: 'N*E*R*D, 2022',
    img: nerdThumb
  },
  {
    project: "dice",
    title: "DICE",
    year: 2022,
    description: `3 different animations for the DICE logo, displayed whenever a user purchases a ticket through the app`,
    client: 'DICE.FM, 2022',
    img: diceThumb
  },
  {
    project: "cabin",
    title: "Cabin Fever",
    year: 2022,
    description: 'Short film about spending too much time inside, commissioned by Giphy to promote their new clips',
    client: 'Giphy, 2022',
    img: cabinThumb
  },
  {
    project: "maya",
    title: 'Maya Delilah',
    year: 2023,
    description: `Official lyric video for Maya Delilah's song "Silver Lining"`,
    client: 'Capitol Music Group, 2023',
    img: mayaThumb
  },
  {
    project: "beacon",
    title: 'Beacon',
    year: 2022,
    description: `Music video for Beacon's "Pay My Debts"`,
    client: 'Apparent Movement, 2022',
    img: beaconThumb
  },
  {
    project: "idiotbox",
    title: "Idiotbox",
    year: 2021,
    description: 'Short animated interlude for Idiotbox, a film about pro surfer Kael Walsh. Directed by Wade Carroll',
    client: 'Quicksilver, 2021',
    img: idiotboxThumb
  }
]
